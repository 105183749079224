import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

/**
 * Class to manage the handle the saving time period
 */
@Injectable({
  providedIn: 'root'
})
export class LoadingStore {
  private loading: BehaviorSubject<boolean> = new BehaviorSubject(false);

  /**
   * Observable to listen and kno if the app is loading
   */
  loading$ = this.loading.asObservable();

  /**
   * Start the loading process
   */
  start(): void {
    this.loading.next(true);
  }

  /**
   * Stop the loading process
   */
  stop(): void {
    this.loading.next(false);
  }
}
